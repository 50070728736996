// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.7rem;
$line-height-base: 1.6;

// Colors
// $blue: #3490dc;
// $indigo: #6574cd;
// $purple: #9561e2;
// $pink: #f66d9b;
// $red: #e3342f;
// $orange: #f6993f;
// $yellow: #ffed4a;
// $green: #38c172;
// $teal: #4dc0b5;
// $cyan: #6cb2eb;
$black: #111 !default;

// Sidebar
$sidebar-width          : 190px !default;
$control-sidebar-width  : 460px !default;
$sidebar-padding-x      : 0.5rem !default;
$sidebar-padding-y      : 0 !default;

//$nav-link-padding-x:
//$sidebar-padding-x: 0.3rem;

$sidebar-dark-submenu-active-bg         : hsla(0, 0%, 86%, 0.9) !default;
$sidebar-dark-submenu-hover-color       : $black !default;

// $zindex-main-sidebar                    : 1040 !default;
$zindex-modal: 1050;