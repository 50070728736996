@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
   max-width:1200px;
  }
}

/*#modal-container .input-group input{
  height: 40px;
}*/

input[type="file"]#picture-upload {
    display: none;
}
.picture-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.profile-user-img {
    margin: 0 auto;
    width: 80%;
    padding: 3px;
    border: 3px solid #adb5bd;
}

.sortable-placeholder {
  background-color: #f3f3f3;
  height: 200px;
  width: 300px;
  border:dashed 1px gray;
}
.sortable-row-placeholder {
  background-color: #f3f3f3;
  height: 100px;
  width: 100%;
  border:dashed 1px gray;
  margin-top: 5px;
}

.nav-sidebar > .nav-item > .nav-link > .nav-icon {
  font-size: 1.0rem !important;
}


.nav-sidebar .nav-treeview > .nav-item > .nav-link > .nav-icon {
  font-size: 0.9rem !important;
}

body.login-page {
  background: url("/img/background.jpg");
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;              /* optional, center the image */
}

body.login-page .card {
  opacity: 0.94;
}


/* @media (min-width: 992px){
  .sidebar-mini.sidebar-collapse .sidebar .user-panel > .info, .sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p, .sidebar-mini.sidebar-collapse .brand-text {
    opacity: 0;
    margin-left: -45px !important;
  }
} */

.overlay {
  position  : fixed;
  display   : none;
  width     : 100%;
  height    : 100%;
  top       : 0;
  left      : 0;
  right     : 0;
  bottom    : 0;
  background-color: rgba(0,0,0,0.5);
  z-index   : 2;
  cursor    : pointer;
}

@media (min-width: 992px) {
  .sidebar-mini.sidebar-collapse .sidebar .user-panel > .logout {
    opacity: 0;
    margin-left: -43px;
  }

  .sidebar-mini.sidebar-collapse .main-sidebar:hover .user-panel > .logout {
    opacity: 1;
    margin-left: 0;
    display: inline-block;
  }
}

/*******************
 ** MODAL LOADING **
 ******************/
 .modalloading {
    position:fixed;
    z-index:10000;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background: rgba( 125, 125, 125, .8 ) 50% 50% no-repeat;
  }
/********************
 ** JSGantt Teawks **
 *******************/

.gmainright{
  width: auto;
}

.gmainleft{
  width: auto;
  flex: 0 0 auto;
}

.gtasktableh tr, .gcharttable tr, .gcharttableh tr, .gtasktable tr {
  height: 31px !important;
}

.gchartgrid {
  overflow-x: auto !important;
}

/********************
 ** ELFINDER TWEAK **
 *******************/

.std42-dialog .ui-dialog-titlebar .elfinder-titlebar-button {
  top: 12px !important;
  left: -7px !important;
}

.elfinder-ltr .elfinder-cwd-view-icons .elfinder-cwd-file {
  margin: 0 10px 40px 0 !important
}

.elfinder .elfinder-cwd-view-icons .elfinder-cwd-file {
  width: 120px !important;
}
/**********************
 ** DATATABLES TWEAK **
 *********************/

div.dt-buttons {
  float: right;
  margin-left:10px;
  clear: none !important;
}

div.dataTables_length {
  float: left;
}

.dataTables_wrapper .dataTables_processing {
  position        : absolute;
  background-color: #007bff;
  color           : white;
  top             : 0px;
}

/******************
 ** RADIO BUTTON **
 *****************/

label.radiobtn span.fa {
  opacity: 0;
}
label.radiobtn.active span.fa {
  opacity: 1;
}

/******************
** UTILITIES CSS **
******************/

.w-10             { width: 10%; }
.w-15             { width: 15%; }
.mw-10            { max-width: 10%; }
.mw-25            { max-width: 25%; }
.c-pointer:hover  { cursor: pointer; }
.c-move:hover     { cursor: move; }

/***********************
 ** FONTAWESOME TWEAK **
 **********************/

 @keyframes fa-blink {
  0% { opacity: 1; }
  25% { opacity: 0.25; }
  50% { opacity: 0.5; }
  75% { opacity: 0.75; }
  100% { opacity: 0; }
}
.fa-blink {
   -webkit-animation: fa-blink .75s linear infinite;
   -moz-animation: fa-blink .75s linear infinite;
   -ms-animation: fa-blink .75s linear infinite;
   -o-animation: fa-blink .75s linear infinite;
   animation: fa-blink .75s linear infinite;
}
/**************
 ** CKEDITOR **
 *************/

figure img {
  width: 100%;
}

div.dt-buttons {
  clear: both;
}

/*.modal {
  z-index: 1000;
}

.modal-backdrop {
  z-index: 999;
}*/

/*****************
 ** TIMETRACKER **
 ****************/

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

/* The sidepanel menu */
.sidepanel {
  height: 250px; /* Specify a height */
  width: 250; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
}

/* The sidepanel links */
.sidepanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidepanel a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidepanel .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Style the button that is used to open the sidepanel */
.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}