// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
// @import '~bootstrap/scss/bootstrap';
@import 'admin-lte';
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import '~bootstrap-icons/font/bootstrap-icons.css';

@import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
@import 'admin-lte/plugins/datatables-buttons/css/buttons.bootstrap4.min.css';
@import 'admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css'; 


@import 'admin-lte/plugins/select2/css/select2.min.css'; 
@import 'admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css'; 

@import 'admin-lte/plugins/uplot/uPlot.min.css'; 
@import 'admin-lte/plugins/fullcalendar/main.css'; 

@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
@import "~bootstrap-daterangepicker/daterangepicker.css";
@import "~bootstrap-colorpicker/dist/css/bootstrap-colorpicker.min.css";

@import "~summernote/dist/summernote-bs4.min.css";
@import "~jsgantt-improved/dist/jsgantt.css";
